import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { css } from 'styled-components'

import { space, fontSizes, mediaQueries, breakpoints, colors } from '../utils/tokens'
import config from '../utils/siteConfig'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { TwoColumnContentLeft } from '../components/Section'
import RichArticle from '../components/RichArticle'
import Container from '../components/Container'

const PageTemplate = ({ data }) => {
  const { title, slug, hero, pageSection, body } = data.contentfulPage
  const postNode = data.contentfulPage

  return (
    <div>
      <Layout>
        <Helmet>
          <title>{`${title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO pagePath={slug} postNode={postNode} pageSEO />
        <main
          css={css({
            paddingTop: `${space[6]}px`,
            [mediaQueries.xxl]: {
              paddingTop: 0,
            },
          })}
        >
          <section
            css={css({
              paddingBottom: `${space[4]}px`,
              paddingLeft: `${space[4]}px`,
              paddingRight: `${space[4]}px`,
              [mediaQueries.lg]: {
                gridGap: `${space[5]}px`,
                padding: `${space[6]}px ${space[6]}px ${space[5]}px 0`,
                maxWidth: breakpoints.xxl,
              },
              [mediaQueries.xxl]: {
                margin: `auto`,
              },
            })}
          >
            <Container>
              <div>
                <h1
                  css={css({
                    marginBottom: `${space[3]}px`,
                    fontWeight: `bold`,
                    [mediaQueries.lg]: {
                      fontSize: fontSizes[6],
                    },
                  })}
                >
                  {hero.title}
                </h1>
              </div>
              {body && <RichArticle body={body} />}
            </Container>
          </section>
          <section
            css={css({
              paddingLeft: `${space[4]}px`,
              paddingRight: `${space[4]}px`,
              [mediaQueries.lg]: {
                padding: `${space[5]}px ${space[6]}px`,
              },
            })}
          >
            <div
              css={css({
                [mediaQueries.lg]: {
                  maxWidth: breakpoints.xl,
                  marginLeft: `auto`,
                },
                [mediaQueries.xxl]: {
                  maxWidth: breakpoints.xxl,
                },
              })}
            >
              <h2
                css={css({
                  fontWeight: `bold`,
                  marginBottom: `${space[4]}px`,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[5],
                  },
                })}
              >
                Services offered at this location
              </h2>
              <ul
                css={css({
                  listStyle: `none`,
                  display: `grid`,
                  gridGap: `${space[3]}px`,
                  [mediaQueries.xxl]: {
                    gridGap: `${space[6]}px`,
                  },
                })}
              >
                {data.allContentfulService.edges.map(({ node }) => {
                  return (
                    <li
                      key={node.id}
                      css={css({
                        padding: `${space[4]}px ${space[3]}px`,
                        marginLeft: `${space[4]}px`,
                        [mediaQueries.xxl]: {
                          display: `grid`,
                          gridGap: `${space[5]}px`,
                          gridTemplateColumns: `1fr 9fr`,
                        },
                      })}
                    >
                      <img
                        src={node.icon.file.url}
                        css={css({
                          maxWidth: `50vw`,
                          maxHeight: `120px`,
                          position: `absolute`,
                          left: `0`,
                          transform: `translateX(-50%)`,
                          zIndex: -1,
                          opacity: 0.5,
                          [mediaQueries.lg]: {
                            position: `initial`,
                            transform: `unset`,
                            opacity: 1,
                            maxHeight: `240px`,
                            width: `10vw`,
                          },
                        })}
                      />
                      <div css={css({})}>
                        <h3
                          css={css({
                            fontSize: fontSizes[4],
                            fontWeight: 500,
                            marginBottom: `${space[3]}px`,
                          })}
                        >
                          {node.name}
                        </h3>
                        {node.longDescription && (
                          <div
                            css={css({
                              maxWidth: `${breakpoints.sm}`,
                              gridColumn: 2,
                              fontSize: `${fontSizes[2]}`,
                              lineHeight: 1.2,
                            })}
                            dangerouslySetInnerHTML={{
                              __html:
                                node.longDescription.childMarkdownRemark.html,
                            }}
                          />
                        )}
                        <Link
                          to={node.fields.path}
                          css={css({
                            display: `inline-block`,
                            textDecoration: `none`,
                            textTransform: `uppercase`,
                            fontSize: `${fontSizes[0]}`,
                            fontWeight: `500`,
                            letterSpacing: `2px`,
                            color: `inherit`,
                            border: `2px solid ${colors.base}`,
                            padding: `${space[3]}px ${space[4]}px`,
                            marginTop: `${space[4]}px`,
                          })}
                        >
                          Learn more
                        </Link>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          {pageSection &&
            pageSection
              .filter(section => section.type !== `google map embed`)
              .map((section, index) => {
                if (section.type === `two-column content left`) {
                  return <TwoColumnContentLeft key={index} section={section} />
                }
              })}
        </main>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query locationQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      hero {
        ...HeroFragment
      }
      metaDescription {
        internal {
          content
        }
      }
      body {
        raw
        references {
          ...RichTextArticleFragment
        }
      }
      pageSection {
        ... on ContentfulPageSection {
          id
          title
          image {
            title
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          type
        }
        body {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 320)
          }
        }
      }
    }
    allContentfulService(sort: { fields: name }) {
      edges {
        node {
          ...ServiceCardFragment
          longDescription {
            childMarkdownRemark {
              html
            }
          }
          fields {
            path
          }
        }
      }
    }
  }
`

export default PageTemplate
